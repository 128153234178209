 .App {
   text-align: center;
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
 }

 body {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   background-color: aliceblue;
 }

 .contnr {
   overflow-x: scroll;
 }

 .navbar {
   /* width: 250px; */
   /* height: 100%; */
   min-height: 900px;
   background-color: #1d2327;
   display: inline-flex;
   justify-content: left;
   align-items: flex-start !important;
 }

 .navbar .links {
   height: 100%;
   font-size: 25px;
   color: white;

 }

 .sidebar-menu-nav {
   height: 100%;
   font-size: 25px;
   color: white;
   height: 35px;
 }

 .links {
   width: 100%;
   text-decoration: none;
 }

 .links a {
   margin: 10px;

   text-decoration: none;
   color: white;
   display: block;
   font-size: 12px;
 }

 .sidebar-menu-nav a {
   margin-left: 20px;
   text-decoration: none;
   color: white;
   display: block;
   font-size: 18px;
   border-bottom: 1px solid rgb(53, 50, 50);
   margin-top: 8px;

 }

 .sidebar-menu-sub-toggle a {
   text-decoration: none;
   font-size: 20px;
 }

 .topmenu>a {
   margin-left: 0px;
 }

 .topmenu>button {
   margin-left: 0px;
   margin-top: 8px;
 }

 .topmenu>button>a {
   margin-left: 0px;
   font-size: 18px;
   border-bottom: 1px solid rgb(53, 50, 50);

  }

 .sidebar-menu-sub-toggle {
   margin-left: 6px;
   background-color: transparent;
   border: none;
 }

 .sidebar-menu-sub-toggle a {
   color: #fff;
 }

 .links a:hover {
   color: #000;
 }

 .title {
   color: blue;
 }

 .nav {
   width: 100%;
 }

 .navbar {
   flex: 1;
   align-items: flex-start;
   padding-top: 0;

 }

 .logo {
   text-align: center;
   /* background-color: darkslategrey; */
   width: 100%;
   margin-top: -5px;
   padding: 10px;
   color: white;
   /* border-bottom: 1px solid #fff; */
   text-decoration: none;
   font-size: 22px;
   width: 250px;
   display: inline-block;
   padding: 0px;
   text-align: left;
 }

 .username {
   text-align: left;
   /* background-color: darkslategrey; */
   width: 100%;
   margin-left: 0px;
   padding: 10px;
   color: white;
 }

 .topbar {
   width: 100%;
   margin: 0px;
   /* padding: 10px; */
   color: black;
   border-bottom: 1px solid #000;
   text-align: right;
   background-color: #1d2327;
 }

 .topbar .links {
   font-size: 14px;
   font-weight: bold;
   color: #fff;
   padding-right: 10px;
 }

 .pagetitle {
   /*  flex: 1; */
   display: inline-block;
   width: 100%;
   font-size: 24px;
   margin-top: 20px;
   margin-bottom: 20px;
   font-weight: 600;
 }

 .titleicon {
   width: 100%;
   margin-top: -5px;
   font-weight: 600;
 }

 .content {
   width: 100%;
   background-color: rgb(30, 30, 197);
   flex: 1;
   align-items: flex-start;
 }

 .login {
   align-items: center;
   justify-items: center;
 }

 .login h2 {
   text-align: center;
 }

 .catsect {
   overflow-y: scroll;
   height: 200px;
   overflow-x: hidden;
 }

 .topcat {

   font-weight: 500;
   color: red;
 }

 .subcat {
   margin-left: 20px;
   font-weight: 500;
   color: blue;
 }

 .subcat2 {
   margin-left: 40px;
   font-weight: 500;
   color: green;
 }

 .counter {
   font-size: 28px;

 }

 .pagination {
   background-color: bisque;
 }

 .pagination>ul {
   display: block;
   width: 50px;
   height: 50px;
   width: 100%;
 }

 .pagination>ul li {
   display: inline-block;
   width: 30px;
   height: 30px;
   border: 1px solid gray;
   margin: 5px;
   text-align: center;
   background-color: aliceblue;
 }

 .pagination>ul li.selected {
   display: inline-block;
   width: 35px;
   height: 35px;
   border: 1px solid lightgray;
   margin: 5px;
   text-align: center;
   background-color: transparent;
 }

 #norecord {
   text-align: center;
 }

 .center {
   text-align: center;
 }

 .mt-20 {
   margin-top: 20px;
 }

 .mb-20 {
   margin-bottom: 20px;
 }

 .pad0 {
   padding-left: 0px;
   padding-right: 0px;
 }

 .flleft {
   text-align: left;
 }

 .logincard {
   margin-top: 20vh;
   margin-bottom: 20vh;

 }

 .logologin {
   display: flex;
   flex: 1;
   width: 100%;
   height: 100px;
   object-fit: contain;

 }

 .logintext {
   text-align: center;
 }

 .footer {
   background-color: #1d2327;
   color: #fff;
   font-size: 18px;
 }

 .rightsect {
   padding-bottom: 50px;
 }

 .cardhead {
   font-weight: bold;
 }

 .mobiletitle {

   text-align: center;
   /* background-color: darkslategrey; */
   padding: 20px;
   color: white;
   /* border-bottom: 1px solid #fff; */
   text-decoration: none;
   font-size: 22px;
   width: 250px;
   display: inline-block;
   padding: 0px;
 }

 .menuicon {
   color: #fff;
   text-align: left;
   padding-top: 3px;
 }

 .showmenu {
   display: block;
   text-align: left;
 }

 .hidemenu {
   display: none;
   text-align: left;
 }

 .list-group-item>span {
   color: #000;
 }

 #viewpost {
   font-size: 14px;
 }

 #slug {
   background-color: lightgray;
 }

 .widget {
   margin-top: 20px;
 }

 .widgettitle {
   color: #fff;
 }

 .widgeticon svg {
   color: #fff;
 }

 .css-tzssek-MuiSvgIcon-root {
   color: #313842;
 }

 .profileimage {
   align-items: center;
 }

 #errormessage {
   color: #f00;
   font-weight: 500;
 }

 .sidebar-menu-sub {
   margin-left: -4px;
 }

 #passmsg {
   color: #f00;
 }

 .sizef {
   width: 200px;
 }

 .modalwidth {
   width: 100%;
 }

 .imgthumb {
   width: 100%;
 }

 .dnd {
   background-color: lightgray;
   border: 1px dashed gray;
   margin-bottom: 5px;
   padding: 5px;
 }

 .tree{
  overflow-x:scroll;
  height:200px;
  columns:2;
  list-style-type: none;
 }

 #manualslug{
    padding-top: 7px;
 }

 